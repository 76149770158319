import React from "react"
// tslint:disable-next-line: max-line-length
import {
  SidePanelMainTopTwo,
  logoutAction,
  cognitoLoginAction,
  cognitoLogoutAction,
} from "@vt-team/design-system-deprecated"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCalendarAlt,
  faSearch,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faSignOutAlt,
  faSignInAlt,
  faFileAlt,
  faTools,
  faUpload,
  faPhone
} from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"

export const defaultLink = {
  framework: "gatsby",
  component: Link,
}
export const sidePanelDefinition = {
  version: require('../../package.json').version,
  top: {
    iconClose: <FontAwesomeIcon icon={faAngleDoubleLeft} />,
    iconOpen: <FontAwesomeIcon icon={faAngleDoubleRight} />,
    mainTop: {
      component: SidePanelMainTopTwo,
      props: {
        logo: {
          url: "https://cdn.vt.team/f/txs.vt.team/logo-icon.web.png",
          alt: "txs-logo",
        },
      },
    },
    link: {
      href: "/",
      ...defaultLink,
    },
  },
  links: [
    {
      link: {
        href: "/whitelist",
        ...defaultLink,
      },
      value: "IP Whitelist",
      icon: <FontAwesomeIcon icon={faSearch} />,
    },
    {
      link: {
        href: "/",
        ...defaultLink,
      },
      value: "Holidays",
      icon: <FontAwesomeIcon icon={faCalendarAlt} />,
    },
    {
      link: {
        href: '/callCenter',
        ...defaultLink,
      },
      value: "Call Quality",
      icon: <FontAwesomeIcon icon={faPhone} />,
    },
    // {
    //   link: {
    //     href: "/reason-codes",
    //     ...defaultLink,
    //   },
    //   value: "Reason Codes",
    //   icon: <FontAwesomeIcon icon={faFileAlt} />,
    // },
    // {
    //   link: {
    //     href: "/rtc",
    //     ...defaultLink,
    //   },
    //   value: "RTC",
    //   icon: <FontAwesomeIcon icon={faTools} />,
    // },
    {
      condition: (_, config) => {
        return config?.isLoggedIn;
      },
      link: {
        href: "/backup",
        ...defaultLink,
      },
      value: "Backup",
      icon: <FontAwesomeIcon icon={faUpload} />,
    },
    {
      condition: (_, config) => {
        return !config?.isLoggedIn
      },
      link: {
        action: cognitoLoginAction,
      },
      value: "Login",
      icon: <FontAwesomeIcon icon={faSignInAlt} />,
    },
    {
      condition: (_, config) => {
        return config?.isLoggedIn
      },
      link: {
        action: cognitoLogoutAction,
      },
      value: "Logout",
      icon: <FontAwesomeIcon icon={faSignOutAlt} />,
    },
  ],
}
