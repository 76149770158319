import React from "react"
import _ from "lodash"
import translationFile from "./translation.json"
import { Link } from "gatsby"
import "./theme.css"
const translate = (keyPath: string) => _.get(translationFile, keyPath, keyPath)

export const withEmergy = (Page: any, overrideProps: any) => {
  return props => {
    return Page({
      ...props,
      ...overrideProps,
      emergy: {
        error: () => {},
        translate,
        link: {
          framework: "gatsby",
          component: Link,
        },
        actualLang: "en",
      },
    })
  }
}
